import {navigate} from "gatsby";
import {getConnected} from "../../util/helper";
const Redirect = (props: any) => {
    let connected = getConnected();
    if (typeof window !== "undefined") {
        if ((!connected || !connected.id) && location.pathname !== `/app/login`) {
            navigate("/app/login");
        } else {
            navigate("/");
        }
    }
    return null
}
export default Redirect;
