import React from "react";
import CaseLawSummariesHeader from "./CaseLawSummariesHeader";
import CaseLawCategoriesList from "../caseLaw/CaseLawCategoriesList";
import {useTranslation} from "gatsby-plugin-react-i18next";

const CaseLawSummariesContent = ({caselawSummaries}:any) => {
    const {t} = useTranslation()

    return (
        <>
            <CaseLawSummariesHeader />
            <div className="container">
                <div className="row">
                    <CaseLawCategoriesList data={caselawSummaries} type="CaseLawSummary" title={t('Case Law Summaries Categories')}/>
                </div>
            </div>
        </>
    )
}

export default CaseLawSummariesContent


