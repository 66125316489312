import React from "react";
import {Router} from "@reach/router";
import Simulator from "./app/simulator";
import SecuredRoute from "../components/auth/SecuredRoute";
import Login from "./app/login";
import Register from "./app/register";
import Redirect from "../components/auth/Redirect";
import Profile from "./app/profile";
import Careers from "./careers";
import ForgotPassword from "./app/forgotPassword";
import PlaceOrder from "./app/place-order";
import Submission from "./app/submission";
import Dashboard from "./app/dashboard";
import Contact from "./contact";
import About from "./about";
import Terms from "./terms";
import OhadarbBlog from "./app/ohadarbBlog";
import OhadarbJournal from "./app/ohadarbJournal";
import OhadarbLaws from "./app/ohadarbLaws";
import CcjaCaseLaw from "./app/ccjaCaseLaw";
import {graphql} from "gatsby";
import Faq from "./faq";
import NationalLaw from "./app/nationalLaw";
import OhadarbLawView from "./app/ohadarbLawView";
import NationalCourtsCaseLaw from "./app/nationalCourtsCaseLaw";
import CaseLawCategorie from "./app/caseLawCategorie";
import LawList from "./app/LawList";
import CaseLawSummaries from "./app/caseLawSummaries";
import OhadarbArchivedJournal from "./app/ohadarbArchivedJournal";
import CCJACaseLawView from "./app/ccjaCaseLawView";

const App = () => {

    return <>
        {/* Version for default language (/:lang/ in path) */}
        <Router basepath="/:lang/app">
            {/*<SecuredRoute path="/simulator" component={Simulator}/>*/}
            <Redirect path="/" />
            <Login path="/login"/>
            <Simulator path="/simulator"/>
            <Register path="/register"/>
            <ForgotPassword path="/forgotPassword"/>
            <Submission path="/submission"/>
            <Contact path="/contact"/>
            <About path="/about"/>
            <Terms path="/terms"/>
            <Faq path="/faq"/>
            <OhadarbBlog path="/ohadarbBlog"/>
            <OhadarbJournal path="/ohadarbJournal"/>
            <CCJACaseLawView path="/ohadarbJournal/:categoryId"/>
            <OhadarbArchivedJournal path="/ohadarbJournal/archived-journal"/>
            <OhadarbArchivedJournal path="/ohadarbJournal/archived-journal/:yearId"/>
            <Profile path="/profile"/>
            <OhadarbLaws path="/ohadarbLaws"/>
            <OhadarbLawView path="/ohadarbLaws/:lawId"/>
            <NationalLaw path="/nationalLaw"/>
            <CcjaCaseLaw path="/ccjaCaseLaw"/>
            <LawList path="/ccjaCaseLaw/:categoryId"/>
            <CCJACaseLawView path="/ccjaCaseLaw/:categoryId/:lawId"/>
            <CaseLawSummaries path="/caseLawSummaries"/>
            <LawList path="/caseLawSummaries/:categoryId"/>
            <CCJACaseLawView path="/caseLawSummaries/:categoryId/:lawId"/>
            <NationalCourtsCaseLaw path="/nationalCourtsCaseLaw"/>
            <CaseLawCategorie path="/nationalCourtsCaseLaw/:country"/>
            <LawList path="/nationalCourtsCaseLaw/:country/:categoryId"/>
            <CCJACaseLawView path="/nationalCourtsCaseLaw/:country/:categoryId/:lawId"/>
            <SecuredRoute path="/profile" component={Profile}/>
            <SecuredRoute path="/dashboard" component={Dashboard}/>
            <SecuredRoute path="/careers" component={Careers}/>
            <SecuredRoute path="/place-order" component={PlaceOrder}/>
        </Router>
        {/* Version for default language (no /:lang/ in path) */}
        <Router basepath="/app">
            {/*<SecuredRoute path="/simulator" component={Simulator}/>*/}

            <Redirect path="/" />
            <Login path="/login"/>
            <Simulator path="/simulator"/>
            <Register path="/register"/>
            <ForgotPassword path="/forgotPassword"/>
            <Submission path="/submission"/>
            <Contact path="/contact"/>
            <About path="/about"/>
            <Terms path="/terms"/>
            <Faq path="/faq"/>
            <OhadarbBlog path="/ohadarbBlog"/>
            <OhadarbJournal path="/ohadarbJournal"/>
            <CCJACaseLawView path="/ohadarbJournal/:categoryId"/>
            <OhadarbArchivedJournal path="/ohadarbJournal/archived-journal/"/>
            <OhadarbArchivedJournal path="/ohadarbJournal/archived-journal/:yearId"/>
            <Profile path="/profile"/>
            <OhadarbLaws path="/ohadarbLaws"/>
            <OhadarbLawView path="/ohadarbLaws/:lawId"/>
            <NationalLaw path="/nationalLaw"/>
            <CcjaCaseLaw path="/ccjaCaseLaw"/>
            <LawList path="/ccjaCaseLaw/:categoryId"/>
            <CCJACaseLawView path="/ccjaCaseLaw/:categoryId/:lawId"/>
            <CaseLawSummaries path="/caseLawSummaries"/>
            <LawList path="/caseLawSummaries/:categoryId"/>
            <CCJACaseLawView path="/caseLawSummaries/:categoryId/:lawId"/>
            <NationalCourtsCaseLaw path="/nationalCourtsCaseLaw"/>
            <CaseLawCategorie path="/nationalCourtsCaseLaw/:country"/>
            <LawList path="/nationalCourtsCaseLaw/:country/:categoryId"/>
            <CCJACaseLawView path="/nationalCourtsCaseLaw/:country/:categoryId/:lawId"/>
            <SecuredRoute path="/profile" component={Profile}/>
            <SecuredRoute path="/dashboard" component={Dashboard}/>
            <SecuredRoute path="/careers" component={Careers}/>
            <SecuredRoute path="/place-order" component={PlaceOrder}/>
        </Router>
    </>

}
export default App;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// https://stackoverflow.com/questions/67242212/gatsby-cant-find-client-routes-when-using-gatsby-plugin-react-i18next
