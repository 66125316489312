import React, {useState} from 'react';
import {useForm, Controller} from "react-hook-form";
import {put} from "../../services/BaseService";
import {usersUrls} from "../../util/api_constant";
import {OpenMessage} from "../../util/helper";
import {useTranslation} from "gatsby-plugin-react-i18next";


const InfoTabs = ({user, reloadData}: any) => {
    const [error, setError] = useState<any>("");
    const [success, setSuccess] = useState<any>("");
    const [eye, setEye] = useState(false);
    const [loading, setLoading] = useState(false);
    const {handleSubmit, control} = useForm();
    const {t} = useTranslation()
    const showPwd = () => setEye(!eye);

    function onSubmit(data: any) {
        let dataToUpdate;
        setLoading(true);
        window.location.replace('/')
        // data.password === null || data.password === "" ?
        //     dataToUpdate = {
        //         id: user.id,
        //         firstName: data.firstName,
        //         lastName: data.lastName,
        //         gender: data.gender,
        //         phoneNumber: data.phoneNumber
        //     }
        //     : dataToUpdate = {
        //         id: user.id,
        //         firstName: data.firstName,
        //         lastName: data.lastName,
        //         gender: data.gender,
        //         phoneNumber: data.phoneNumber,
        //         password: data.password
        //     }
        // put(`${usersUrls.profil}`, dataToUpdate).then(() => {
        //     setSuccess("Mise à jour effectuer avec succès")
        //     setError("")
        //     reloadData();
        // })
        //     .catch((e) => {
        //         setError(e.response.data)
        //         setSuccess("")
        //     })
        //     .finally(() => {
        //         setLoading(false)
        //     })
    }

    const handleChange = (e: { target: { value: any; }; }) => {
        console.log(e.target.value)
    }

    return (
        <>
            {error !== "" && OpenMessage(error)}
            {success !== "" && (<span className="success-submit-msg"><strong>{success}</strong></span>)}
            {user !== null ?
                <div className="row" id="contact-form">
                    <div className="col-xl-10 mx-auto">
                        <div className="row gy-10 gx-lg-8 gx-xl-12">
                            <div className="col-lg-8">
                                <form className="contact-form needs-validation" onSubmit={handleSubmit(onSubmit)}
                                      noValidate>
                                    <div className="messages"></div>
                                    <div className="row gx-4">
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input
                                                    id="form_firstname"
                                                    type="text"
                                                    name="firstName"
                                                    className="form-control"
                                                    placeholder="Doe"
                                                    value={user.firstName || 'OhadArb'}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="form_name">{t('firstName')} *</label>
                                                <div className="valid-feedback"> {t('looksGood')}</div>
                                                <div className="invalid-feedback"> {t('enterFirstName')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input
                                                    id="form_lastname"
                                                    type="text"
                                                    name="lastName"
                                                    className="form-control"
                                                    placeholder="Doe"
                                                    value={user.lastName || 'User'}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="form_lastname">{t('lastName')} *</label>
                                                <div className="valid-feedback"> {t('looksGood')}</div>
                                                <div className="invalid-feedback"> {t('enterLasttName')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input
                                                    id="form_email"
                                                    type="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="jane.doe@example.com"
                                                    value={user.username || 'contact@ohadarbitration.com'}
                                                    required/>
                                                <label htmlFor="form_email">Email *</label>
                                                <div className="valid-feedback"> {t('looksGood')}</div>
                                                <div className="invalid-feedback"> {t('enterEmailName')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-select-wrapper mb-4">
                                                <select
                                                    className="form-select"
                                                    id="form-select"
                                                    disabled
                                                    value={user.roles?.[0].name || t('OhadArb Member')}
                                                    onChange={handleChange}
                                                    name="Status"
                                                    required>
                                                    <option value={t('ADMIN')}>{t('Admin')}</option>
                                                    <option value={t('Student')}>{t('Student')}</option>
                                                    <option value={t('Ohadarb Member')}>{t('OhadArb Member')}</option>
                                                    <option value={t('Professor')}>{t('Professor')}</option>
                                                    <option value={t('Professional')}>{t('Professional')}</option>
                                                </select>
                                                <div className="valid-feedback">{t('looksGood')}</div>
                                                <div className="invalid-feedback"> {t('enterReasonName')}</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-check mb-4">
                                                <input
                                                    name="agree"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked
                                                    value=""
                                                    id="invalidCheck" required/>
                                                <label className="form-check-label"
                                                       htmlFor="invalidCheck"> {t('Iagreeto')}
                                                    <a href="/app/terms" className="hover">{t('termsAndPolicy')}</a>.
                                                </label>
                                                <div className="invalid-feedback"> {t('checkAgreeQ')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex flex-row justify-content-around">
                                            <input
                                                name="message"
                                                type="submit"
                                                className="btn btn-primary rounded-pill btn-send mb-3"
                                                value="Validate"
                                            />
                                            <p className="text-muted"><strong>*</strong> {t('requiredFields')}
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                // <form onSubmit={handleSubmit(onSubmit)}>
                //     <div className="row">
                //         <div className="col-sm-6">
                //             <div className="mb-3">
                //                 <label htmlFor="inputGroupMergeFirstName" className="form-label">Prénom</label>
                //                 <div className="input-group-merge">
                //                     <div className="input-group-prepend input-group-text"
                //                          id="inputGroupMergeFirstNameAddOn">
                //                         <i className="bi-person"/>
                //                     </div>
                //                     <Controller
                //                         name="firstName"
                //                         control={control}
                //                         defaultValue={user.firstName}
                //                         rules={{required: true}}
                //                         render={({field, formState: {errors}}) =>
                //                             <>
                //                                 <input className="form-control"
                //                                        id="inputGroupMergeFirstName"
                //                                        placeholder="Prénom"
                //                                        aria-label="Prénom"
                //                                        aria-describedby="inputGroupMergeFirstNameAddOn"
                //                                        {...field}
                //                                 />
                //                                 <div
                //                                     className="error-msg">  {errors.firstName && "Prénom obligatoire"}</div>
                //                             </>
                //                         }
                //                     />
                //                 </div>
                //             </div>
                //         </div>
                //         <div className="col-sm-6">
                //             <div className="mb-3">
                //                 <label htmlFor="inputGroupMergeLastName" className="form-label">Nom</label>
                //                 <div className="input-group-merge">
                //                     <div className="input-group-prepend input-group-text"
                //                          id="inputGroupMergeLastNameAddOn">
                //                         <i className="bi-person"/>
                //                     </div>
                //                     <Controller
                //                         name="lastName"
                //                         control={control}
                //                         defaultValue={user.lastName}
                //                         rules={{required: true}}
                //                         render={({field, formState: {errors}}) =>
                //                             <>
                //                                 <input className="form-control"
                //                                        id="inputGroupMergeLastName"
                //                                        placeholder="Nom"
                //                                        aria-label="Nom"
                //                                        aria-describedby="inputGroupMergeLastNameAddOn"
                //                                        {...field}
                //                                 />
                //                                 <div
                //                                     className="error-msg">  {errors.lastName && "Nom obligatoire"}</div>
                //                             </>
                //                         }
                //                     />
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                //     {/* <div className="mb-3">
                //         <label htmlFor="inputGroupMergeEmail" className="form-label">Email</label>
                //         <div className="input-group-merge">
                //             <div className="input-group-prepend input-group-text" id="inputGroupMergeEmailAddOn">
                //                 <i className="bi-envelope-open" />
                //             </div>
                //             <input
                //                 type="text"
                //                 className="form-control"
                //                 id="inputGroupMergeEmail"
                //                 placeholder="email@example.com"
                //                 aria-label="email@example.com"
                //                 aria-describedby="inputGroupMergeEmailAddOn"
                //             />
                //         </div>
                //     </div>*/}
                //     <div className="row">
                //         <div className="col-sm-6">
                //             <div className="mb-3">
                //                 <label htmlFor="inputGroupMergePhoneNumber" className="form-label">Téléphone</label>
                //                 <div className="input-group-merge">
                //                     <div className="input-group-prepend input-group-text"
                //                          id="inputGroupMergePhoneNumberAddOn">
                //                         <i className="bi-phone"/>
                //                     </div>
                //                     <Controller
                //                         name="phoneNumber"
                //                         control={control}
                //                         defaultValue={user.phoneNumber}
                //                         rules={{required: true}}
                //                         render={({field, formState: {errors}}) =>
                //                             <>
                //                                 <input type="number"
                //                                        className="form-control"
                //                                        id="inputGroupMergePhoneNumber"
                //                                        placeholder="Téléphone"
                //                                        aria-label="Téléphone"
                //                                        aria-describedby="inputGroupMergePhoneNumberAddOn"
                //                                        {...field}
                //                                 />
                //                                 <div
                //                                     className="error-msg">  {errors.phoneNumber && "Téléphone obligatoire"}</div>
                //                             </>
                //                         }
                //                     />
                //                 </div>
                //             </div>
                //         </div>
                //         <div className="col-sm-6">
                //             <div className="mb-3">
                //                 <label htmlFor="inputGroupMergeGenderSelect" className="form-label">Genre</label>
                //                 <div className="input-group-merge">
                //                     <div className="input-group-prepend input-group-text">
                //                         <i className="bi-person"/>
                //                     </div>
                //                     <Controller
                //                         name="gender"
                //                         control={control}
                //                         defaultValue={user.gender}
                //                         rules={{required: true}}
                //                         render={({field, formState: {errors}}) =>
                //                             <>
                //                                 <select id="inputGroupMergeGenderSelect" className="form-select"
                //                                         placeholder="Choisissez un genre"
                //                                         {...field}>
                //                                     <option key="M" value="M">Homme</option>
                //                                     <option key="F" value="F">Femme</option>
                //                                 </select>
                //                                 <div
                //                                     className="error-msg">  {errors.gender && "Genre obligatoire"}</div>
                //                             </>
                //                         }
                //                     />
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                //     <div className="mb-3">
                //         <label htmlFor="inputGroupMergePassword" className="form-label">Mot de passe</label>
                //         <div className="input-group-merge">
                //             <div className="input-group-prepend input-group-text" id="inputGroupMergePasswordAddOn">
                //                 <i className="bi-key-fill"/>
                //             </div>
                //             <Controller
                //                 name="password"
                //                 control={control}
                //                 defaultValue=""
                //                 render={({field}) =>
                //                     <>
                //                         <input type={eye ? "text" : "password"}
                //                                className="form-control"
                //                                id="inputGroupMergePassword"
                //                                placeholder="********"
                //                                aria-label="********"
                //                                aria-describedby="inputGroupMergePasswordAddOn"
                //                                {...field}
                //                         />
                //                         <span id="changePassTarget" className="input-group-append input-group-text"
                //                               onClick={showPwd}><i id="changePassIcon"
                //                                                    className={eye ? "bi-eye-slash" : "bi-eye"}/>
                //                         </span>
                //                     </>
                //                 }
                //             />
                //         </div>
                //     </div>
                //     <div>
                //         <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
                //             {loading ? "En cours ..." : "Mettre à jour"}
                //         </button>
                //     </div>
                // </form>
                : <> ... </>
            }
        </>
    );
}

export default InfoTabs;
