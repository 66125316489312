import {loadScript} from "../../util/helper";
import Layout from "../../components/layout/Layout";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";
import React from "react";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";
import CaseLawSummariesContent from "../../components/CaseLawSummaries/CaseLawSummariesContent";
import {getCaseLawSummaryCategories} from "../../queries";

const CaseLawSummaries = ({}: any) => {
    const {t, i18n} = useTranslation()
    const [caselawSummaries, setCaselawSummaries] = useState<any>(null);

    useEffect(() => {
        getCaseLawSummaryCategories(setCaselawSummaries, i18n.language)
        loadScript("/assets/js/plugins.js");
        setTimeout(() => {
            loadScript("/assets/js/theme.js");
        }, 500)
    }, [])

    return (
        <>
            <Helmet>
                <title>OHADARB | {t('caseLawSummaries')}</title>
            </Helmet>
            <Layout title={'OHADARB | Case Law Summaries'}>
                {caselawSummaries === null ?
                    <div className="page-loader"></div>:
                    <CaseLawSummariesContent caselawSummaries={caselawSummaries} />
                }
            </Layout>
        </>
    )
}

export default CaseLawSummaries;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
