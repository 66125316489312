import React from 'react';
import InfoTabs from "./infoTabs";

const ProfileContent = ({user, reloadData}: any) => {

    return (
        <div className="content-wrapper">
            <section className="wrapper bg-light lower-end">
                <div className="container py-14 py-md-16">
                    <div className="card shadow-lg">
                        <div className="card-body">
                            <div className='wrapper'>
                                <ul className="nav nav-tabs nav-tabs-basic">
                                    <li className="nav-item"><a className="nav-link active" data-bs-toggle="tab"
                                                                href="#tab3-1">Profile</a>
                                    </li>
                                    <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab3-2">Address</a>
                                    </li>
                                    <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab3-3">Subscription</a>
                                    </li>
                                </ul>
                                <div className="tab-content mt-0 mt-md-5">
                                    <div className="tab-pane fade show active" id="tab3-1">
                                        <InfoTabs user={user|| {}} reloadData={reloadData}/>
                                    </div>
                                    <div className="tab-pane fade" id="tab3-2">
                                        Your Address
                                    </div>
                                    <div className="tab-pane fade" id="tab3-3">
                                        Your Subscription
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default ProfileContent;
