import React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next";

const CaseLawSummariesHeader = ({}: any) => {
    const {t} = useTranslation()
    return (
        <>
            <section className="wrapper image-wrapper bg-image bg-overlay text-white"
                     data-image-src="/assets/img/justice.jpg">
                <div className="container pt-19 pt-md-21 pb-18 pb-md-20 text-center">
                    <div className="row">
                        <div className="col-md-10 col-lg-12 col-xl-12 col-xxl-12 mx-auto">
                            <h1 className="display-1 text-white mb-3">{t('caseLawSummaries')}</h1>
                            <p className="lead fs-lg px-md-3 px-lg-7 px-xl-8 px-xxl-2 text-justify">
                                {t('HeaderInitText')}
                            </p></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CaseLawSummariesHeader
